/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import menuTree from '../data/menuTree'
import select from '../components/utils'
import { FormattedMessage } from 'react-intl'
import Link from 'gatsby-link'
import Content, { HTMLContent } from '../components/Content'

export const ServicesPostTemplate = ({
    content,
    contentComponent,
    description,
    title,
    helmet,
    langFix,
    sel,
}) => {
    const PostContent = contentComponent || Content
    return (
        <section className="is-blog-section">
            {helmet || ''}
            <div className="container content">
                <div className="hero is-small">
                    <div className="hero-body">
                        <div className="columns is-multiline">
                            <div className="column is-offset-1 is-5">
                                <h2 className="is-size-3-rem has-text-weight-normal is-title-color is-size-1-mobile">
                                    {title}
                                </h2>
                            </div>
                            <div className="column is-5">
                                <p className="is-size-6 is-font-secondary has-text-dark has-text-weight-normal">
                                    {description}
                                </p>
                                <Link
                                    to={
                                        langFix + '/' + menuTree.hire[sel] + '/'
                                    }
                                >
                                    <button className="button is-medium has-text-weight-medium is-primary is-outlined">
                                        <FormattedMessage id="services.hire.cta" />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-6 is-font-secondary">
                    <PostContent content={content} />
                </div>
                <div className="column is-10 has-text-right">
                    <Link to={langFix + '/' + menuTree.hire[sel] + '/'}>
                        <h3 className="has-text-weight-normal has-text-primary has-underline-from-center-cta is-size-1">
                            <FormattedMessage id="services.hire.cta" />
                        </h3>
                    </Link>
                </div>
            </div>
        </section>
    )
}

ServicesPostTemplate.propTypes = {
    data: PropTypes.object,
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
    location: PropTypes.string,
    tags: PropTypes.array,
    langKey: PropTypes.string,
    image: PropTypes.object,
    imageAlt: PropTypes.string,
    date: PropTypes.string,
    timeToRead: PropTypes.string,
}

const ServicesPost = ({ data, location }) => {
    const { markdownRemark: post } = data
    const jsonData = data.allArticlesJson.edges[0].node.articles
    const langKey = post.frontmatter.lang
    const langFix = langKey === 'en' ? '' : 'ka'
    const meta = data.site.siteMetadata
    const sel = select(langKey)
    return (
        <Layout
            className="container"
            data={data}
            jsonData={jsonData}
            location={location}
        >
            <SEO frontmatter={post.frontmatter} />
            <ServicesPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                helmet={
                    <Helmet titleTemplate={`%s - ${meta.title}`}>
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                slug={post.frontmatter.slug}
                langFix={langFix}
                sel={sel}
                langKey={langKey}
            />
        </Layout>
    )
}

ServicesPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        site: PropTypes.object,
        allArticlesJson: PropTypes.object,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default ServicesPost

export const pageQuery = graphql`
    query ServicesPostByID($id: String!) {
        site {
            siteMetadata {
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            timeToRead
            frontmatter {
                slug
                id
                title
                description
                lang
            }
        }
    }
`
